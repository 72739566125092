const info = {
    LegalFare:
        '本计算器的法律依据是2007年4月1日起施行的《诉讼费用交纳办法》，由于省、自治区、直辖市人民政府可以结合本地实际情况在规定的幅度内制定具体交纳标准，本次计算结果仅供参考。',
    InjuryOnTheJob:
        '本计算器的数据系长春市2021年工伤赔偿计算标准，关于工伤职工应享有的待遇，因为各地、各年的赔偿标准均不同，因此工伤职工、工亡职工亲属在主张各项赔偿项目时，应根据具体实际情况并参照当地具体规定做相应的调整，预估结果仅供参考，实际费用以当地实际情况为准！',
    Damage:
        '本计算器的法律依据是《中华人民共和国民法典》和《最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》等，所计算的损失赔偿数额并不代表最终的结果，具体的损失赔偿数额双方可以协商确定，也可以由法院进行判决，预估结果仅供参考，实际费用以当地实际情况为准！',
    Traffic:
        '本计算器的法律依据是《中华人民共和国民法典》和《最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》等，所计算的损失赔偿数额并不代表最终的结果，具体的损失赔偿数额双方可以协商确定，也可以由法院进行判决，预估结果仅供参考，实际费用以当地实际情况为准！',
}

export { info }
