<template>
    <a-form-model-item :label="font">
        <a-input v-model="form[label]" @change="onchange" :suffix="suffix ? suffix : ' '" />
        <div :class="suffix == '元' && form[label] ? 'info' : 'info1'">{{ info }}</div>
    </a-form-model-item>
</template>

<script>
    export default {
        name: 'calculator-inputNumber',
        props: {
            form: {},
            label: {},
            suffix: {},
            font: {},
            maxLength: {
                type: Number,
                default: 12,
            },
        },
        data() {
            return {
                info: '整',
            }
        },
        methods: {
            onchange(value) {
                if (this.form[this.label].length > this.maxLength) {
                    this.$message.warning(`${this.font}超出最大长度${this.maxLength}位限制`, 1)
                }

                this.form[this.label] = this.form[this.label]
                    .replace(/[^\d]/g, '')
                    .substr(0, this.maxLength)
                    .toString()
                this.info = this.number_chinese(this.form[this.label])
            },
            number_chinese(str) {
                var num = parseFloat(str)
                var strOutput = '',
                    strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分'
                num += '00'
                var intPos = num.indexOf('.')
                if (intPos >= 0) {
                    num = num.substring(0, intPos) + num.substr(intPos + 1, 2)
                }
                strUnit = strUnit.substr(strUnit.length - num.length)
                for (var i = 0; i < num.length; i++) {
                    strOutput +=
                        '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1)
                }
                return strOutput
                    .replace(/零角零分$/, '整')
                    .replace(/零[仟佰拾]/g, '零')
                    .replace(/零{2,}/g, '零')
                    .replace(/零([亿|万])/g, '$1')
                    .replace(/零+元/, '元')
                    .replace(/亿零{0,3}万/, '亿')
                    .replace(/^元/, '零元')
            },
        },
    }
</script>

<style scoped lang="less">
    @import 'calculator.less';
    .ant-input-affix-wrapper {
        /deep/.ant-input {
            height: 0.5rem;
            border-radius: 0.08rem;
            border: 1px solid #d9d9d9 !important;
            padding: 0 0.28rem;
            color: rgba(0, 0, 0, 0.85) !important;
            font-size: 0.16rem !important;
        }
    }
    .info {
        padding-left: 30px;
        /* padding-top: 20px; */
        /* line-height: 20px; */
        margin-bottom: -40px;
        color: @primary-color;
    }
    .info1 {
        padding-left: 30px;
        margin-bottom: -40px;
        color: transparent;
    }
</style>
