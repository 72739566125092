<template>
    <div class="button-view">
        <a-button type="primary" @click="start">开始计算</a-button>
        <a-button @click="reset">重置</a-button>
    </div>
</template>

<script>
    export default {
        name: 'button-view',
        methods: {
            start() {
                this.$emit('start', () => {
                    // this.$nextTick(() => {
                    //     let question = document.getElementById('question')
                    //     question.scrollTo(0, question.scrollHeight)
                    // })
                    setTimeout(() => {
                        let question = document.getElementById('question')
                        question.scrollTo(0, question.scrollHeight)
                    }, 100)
                })
            },
            reset() {
                this.$emit('reset')
            },
        },
    }
</script>

<style scoped lang="less">
    .button-view {
        margin: 0 auto 0.4rem;
        width: 3rem;
        display: flex;
        justify-content: space-between;

        .ant-btn {
            height: 0.4rem;
            min-width: 1.2rem;
            font-size: 0.16rem;
            border-radius: 8px;
        }
        .ant-btn-primary {
            background-color: #358cf3;
            border-color: #358cf3;
        }
    }
</style>
