<template>
    <div ref="asd">
        <a-form-model-item :label="font">
            <a-select
                v-model="form[label]"
                class="select"
                dropdownClassName="calculator-select"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                @change="handleChange"
            >
                <a-select-option v-for="item in data" :key="item.id" :value="item.id">
                    {{ item.name }}
                </a-select-option>
            </a-select>
        </a-form-model-item>
    </div>
</template>

<script>
    export default {
        name: 'calculator-select',
        props: ['form', 'label', 'data', 'font'],
        methods: {
            handleChange(value) {
                this.$emit('handleChange', {
                    label: this.label,
                    value: value,
                })
            },
        },
    }
</script>

<style scoped lang="less">
    @import 'calculator.less';
    .select {
        height: 0.5rem;
        /deep/.ant-select-selection {
            height: 0.5rem;
            border-radius: 0.08rem;
        }
        /deep/.ant-select-selection__rendered,
        /deep/.ant-select-selection-selected-value {
            padding: 0 0.1rem;
            height: 100%;
            line-height: 0.5rem;
            font-size: 0.16rem;
        }
    }
</style>

<style lang="less">
    .calculator-select {
        .ant-select-dropdown-menu {
            max-height: 3rem;
            &::-webkit-scrollbar {
                width: 2px;
                background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #358cf3;
            }
            .ant-select-dropdown-menu-item {
                line-height: 0.4rem !important;
            }
        }
    }

    @media screen and (width: 1360px) {
        .ant-select-dropdown-menu {
            max-height: 2.46rem !important;
        }
    }

    @media screen and (width: 1600px) {
        .ant-select-dropdown-menu {
            max-height: 2.6rem !important;
        }
    }

    @media screen and (width: 1280px) {
        .ant-select-dropdown-menu {
            max-height: 2.2rem !important;
        }
    }
</style>
