<template>
    <div>
        <div class="font"
            ><span>计算结果：</span>
            <a-button
                v-if="result.length > 0"
                type="primary"
                class="copyTrackingNumber"
                data-clipboard-target="#content"
                @click="copyToClipboard()"
                >复制结果</a-button
            >
        </div>
        <div id="content">
            <div class="result-main" v-for="(item, index) in result" :key="index">
                <div class="font font-result">
                    <span>{{ item.name }}</span>
                    <b>{{ item.money }}</b>
                    <strong>元</strong>
                </div>
            </div></div
        >
    </div>
</template>

<script>
    import ClipboardJS from 'clipboard'
    export default {
        name: 'calculator-result-classification',
        props: ['result'],

        methods: {
            copyToClipboard() {
                let that = this
                // 括号中的内容：对应复制按钮的class
                let clipboard = new ClipboardJS('.copyTrackingNumber')
                clipboard.on('success', function (e) {
                    that.$message.success('复制成功')
                    e.clearSelection()
                })
                clipboard.on('error', function (e) {
                    that.$message.error('复制失败，请重试')
                })
            },
        },
    }
</script>

<style scoped lang="less">
    .font {
        font-size: 0.2rem;
        margin-top: 0.3rem;
        color: #333;

        span {
            display: inline-block;
            width: 3rem;
        }
        b,
        strong {
            color: #333;
            font-weight: normal;
        }
    }

    .result-main:last-child {
        .font-result {
            font-weight: 900;
            font-size: 0.24rem;
            color: #333;

            b,
            strong {
                color: #333;
                font-weight: 600;
            }
        }
    }

    b {
        margin: 0 0.05rem;
    }
    strong {
        font-size: 0.16rem;
    }

    .copyTrackingNumber {
        border-radius: 4px;
        background-color: #358cf3;
        border-color: #358cf3;
    }
</style>
