<template>
    <div>
        <a-divider />
        <div class="info-main">
            <div>
                <a-icon type="info-circle" />
                <span>注：</span>
            </div>
            <div>{{ mark }}</div>
        </div>
    </div>
</template>

<script>
    import { info } from '@/pages/PC/view/calculator/data/info'
    export default {
        name: 'info',
        data() {
            return {
                info,
            }
        },
        props: ['mark'],
    }
</script>

<style scoped lang="less">
    .info-main {
        display: flex;
        align-items: flex-start;
        div:first-child {
            margin: 0 auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 0.16rem;
            span {
                margin-left: 0.05rem;
            }
            i {
                color: @primary-color;
                font-size: 0.18rem;
            }
        }
        div:last-child {
            flex: 1;
            font-size: 0.16rem;
            word-break: break-all;
        }
    }
</style>
